import {
  ISSUE_ACTION_TARGET,
  ISSUE_ACTION_TYPE,
} from '@backmarket/http-api/src/api-specs-help-center/diagnosis/types/common'
import { CUSTOMER_ISSUE } from '@backmarket/http-api/src/api-specs-help-center/diagnosis/types/issueListResponse'
import {
  CLAIM_KIND_ENUM,
  DECLARE_WARRANTY_STATE_ENUM,
  RESOLUTION_OPTION_TYPE_ENUM,
} from '@backmarket/http-api/src/api-specs-resolution-engine/types/common'
import { intersection } from '@backmarket/utils/collection/intersection'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import type {
  BaseActionObject,
  InternalMachineOptions,
  ResolveTypegenMeta,
  ServiceMap,
} from 'xstate'

import { isInvalidProblemErrorType } from '@/scopes/customer-care/common/machine/guards/errors'
import { ReturnFunnelResolutionActions } from '~/scopes/customer-care/resolution/machine/schemas/ReturnFunnel/ReturnFunnel.constants'

import type { MultipleCondType } from '../../common/machine/guards/guards'

import {
  MAX_FILES_SIZE,
  MAX_FILE_SIZE,
} from './ResolutionFlow.machine.constants'
import type {
  MachineContext,
  MachineEvents,
  MachineStateSchema,
  ResolutionOptionsEvents,
} from './ResolutionFlow.machine.types'

const isMerchantClaim = ({ pointOfContact }: MachineContext) =>
  pointOfContact === CLAIM_KIND_ENUM.merchant

export const ResolutionFlowMachineGuards: InternalMachineOptions<
  MachineContext,
  MachineEvents,
  ResolveTypegenMeta<
    MachineStateSchema,
    MachineEvents,
    BaseActionObject,
    ServiceMap
  >,
  true
>['guards'] = {
  every: (context, event, meta) => {
    const { guards } = meta.cond as MultipleCondType

    return guards.every((guardKey) => {
      return meta.state.machine?.options?.guards?.[guardKey]?.(
        context,
        event,
        meta,
      )
    })
  },
  hasEmptySelfTroubleshootingArticles: ({ selfTroubleshootingArticles }) =>
    isEmpty(selfTroubleshootingArticles),
  hasSelfTroubleshootingArticles: (_context, event) => !isEmpty(event.data),
  isBmWarrantyClaimTargetFromDiagnosisV2: ({ diagnosisV2Action }) =>
    !isEmpty(diagnosisV2Action) &&
    diagnosisV2Action.type === ISSUE_ACTION_TYPE.internalRedirect &&
    diagnosisV2Action.target === ISSUE_ACTION_TARGET.bmWarrantyClaim,
  isChatPayload: (_, event) => {
    const { payload } = event as ResolutionOptionsEvents

    return payload.resolutionOption?.type === RESOLUTION_OPTION_TYPE_ENUM.chat
  },
  isCustomerIssueOrderlineLateDelivery: ({ diagnosisV2CustomerIssues }) =>
    diagnosisV2CustomerIssues.includes(CUSTOMER_ISSUE.deliveryLateDelivery),
  isCustomerIssueOrderlineNotShipped: ({ diagnosisV2CustomerIssues }) =>
    diagnosisV2CustomerIssues.includes(CUSTOMER_ISSUE.orderlineNotShipped),
  isCustomerIssueOrderlineNotValidated: ({ diagnosisV2CustomerIssues }) =>
    diagnosisV2CustomerIssues.includes(CUSTOMER_ISSUE.orderlineNotValidated),
  isGetResolutionOptionsTargetFromDiagnosisV2: ({ diagnosisV2Action }) =>
    !isEmpty(diagnosisV2Action) &&
    diagnosisV2Action.type === ISSUE_ACTION_TYPE.internalRedirect &&
    diagnosisV2Action.target === ISSUE_ACTION_TARGET.getResolutionOptions,
  isGetResolutionOptionsWithoutWarrantyExclusionTarget: ({
    diagnosisV2Action,
  }) =>
    !isEmpty(diagnosisV2Action) &&
    diagnosisV2Action.type === ISSUE_ACTION_TYPE.internalRedirect &&
    diagnosisV2Action.target ===
      ISSUE_ACTION_TARGET.getResolutionOptionsNoWarrantyExclusion,
  isInvalidProblemErrorType,
  isLiveChatAsInitiatedResolution: ({ initiatedResolution }) =>
    initiatedResolution?.resolutionOption?.type ===
    RESOLUTION_OPTION_TYPE_ENUM.liveChat,
  isLiveChatPayload: (_, event) => {
    const { payload } = event as ResolutionOptionsEvents

    return (
      payload.resolutionOption?.type === RESOLUTION_OPTION_TYPE_ENUM.liveChat
    )
  },
  isMerchantClaim,
  isMerchantClaimWithOnlyAssistance: (context, event) => {
    const resolutionOptions =
      event.type === 'BACK'
        ? context.resolutionOptions
        : event?.data?.resolutionOptions

    if (isEmpty(resolutionOptions) || !Array.isArray(resolutionOptions)) {
      return false
    }

    const hasOnlyOneOption = resolutionOptions.length === 1

    const hasOnlyAssistanceOption = resolutionOptions.some(
      (option) => option.type === RESOLUTION_OPTION_TYPE_ENUM.remoteAssistance,
    )

    return (
      isMerchantClaim(context) && hasOnlyOneOption && hasOnlyAssistanceOption
    )
  },
  isMerchantClaimWithOptions: (context) => {
    const { resolutionOptions } = context

    if (isEmpty(resolutionOptions) || !Array.isArray(resolutionOptions)) {
      return false
    }

    return isMerchantClaim(context) && resolutionOptions.length > 1
  },
  isContactOrderlineLifecycleAsFirststate: ({ firstStateAfterLoading }) =>
    firstStateAfterLoading ===
      'CONTACT.ORDERLINE_TIMELINE_LATE_DELIVERY_FORM' ||
    firstStateAfterLoading === 'CONTACT.ORDERLINE_TIMELINE_NOT_SHIPPED_FORM' ||
    firstStateAfterLoading === 'CONTACT.ORDERLINE_TIMELINE_NOT_VALIDATED_FORM',

  isOutOfWarranty: ({ customerInput }) =>
    !isEmpty(customerInput.declaredWarrantyState) &&
    customerInput.declaredWarrantyState !==
      DECLARE_WARRANTY_STATE_ENUM.inWarranty,
  isOutOfWarrantyPayload: (_, event) =>
    !isEmpty(event.payload.declaredWarrantyState) &&
    event.payload.declaredWarrantyState !==
      DECLARE_WARRANTY_STATE_ENUM.inWarranty,
  isRemoteAssistanceAsInitiatedResolution: ({ initiatedResolution }) =>
    initiatedResolution?.resolutionOption?.type ===
    RESOLUTION_OPTION_TYPE_ENUM.remoteAssistance,
  isRemoteAssistanceTargetFromDiagnosisV2: ({ diagnosisV2Action }) =>
    !isEmpty(diagnosisV2Action) &&
    diagnosisV2Action.type === ISSUE_ACTION_TYPE.internalRedirect &&
    (diagnosisV2Action.target === ISSUE_ACTION_TARGET.remoteAssistance ||
      diagnosisV2Action.target === ISSUE_ACTION_TARGET.invoiceRequestPending ||
      diagnosisV2Action.target === ISSUE_ACTION_TARGET.showImei ||
      diagnosisV2Action.target === ISSUE_ACTION_TARGET.imeiSelfcareContent),
  isRemoteAssistancePayload: (_, event) => {
    const { payload } = event as ResolutionOptionsEvents

    return (
      payload.resolutionOption.type ===
      RESOLUTION_OPTION_TYPE_ENUM.remoteAssistance
    )
  },
  isSellerTypeAsInitiatedResolution: ({ initiatedResolution }) =>
    [
      RESOLUTION_OPTION_TYPE_ENUM.factoryRepair,
      RESOLUTION_OPTION_TYPE_ENUM.refund,
      RESOLUTION_OPTION_TYPE_ENUM.repairReplace,
      RESOLUTION_OPTION_TYPE_ENUM.sellerRepair,
      RESOLUTION_OPTION_TYPE_ENUM.sellerReplace,
    ].some(
      (element) => element === initiatedResolution?.resolutionOption?.type,
    ),
  isSelfTroubleshootingPreviousState: (
    { selfTroubleshootingArticles },
    __,
    meta,
  ) => {
    const { history } = meta.state

    if (history) {
      return (
        history.matches('SELF_TROUBLESHOOTING') &&
        !isEmpty(selfTroubleshootingArticles)
      )
    }

    return false
  },

  isStraightRefundAsInitiatedResolution: ({ initiatedResolution }) =>
    initiatedResolution?.resolutionOption?.type ===
    RESOLUTION_OPTION_TYPE_ENUM.straightRefund,

  isStraightRefundPayload: (_context, event) => {
    const { payload } = event as ResolutionOptionsEvents

    return (
      payload.resolutionOption?.type ===
      RESOLUTION_OPTION_TYPE_ENUM.straightRefund
    )
  },
  isValidSelectedResolutionOption: (_, event) => {
    const { payload } = event as ResolutionOptionsEvents

    const validResolutionOptions = Object.values(RESOLUTION_OPTION_TYPE_ENUM)

    return validResolutionOptions.includes(payload.resolutionOption?.type)
  },
  validateContactFormPayload: (_, event) => {
    const overSizedPictures = event.payload.pictures.filter(
      (picture) => picture.size > MAX_FILE_SIZE,
    )

    if (!isEmpty(overSizedPictures)) {
      return false
    }

    const totalPicturesSize = event.payload.pictures.reduce(
      (totalSize, picture) => totalSize + picture.size,
      0,
    )

    if (totalPicturesSize > MAX_FILES_SIZE) {
      return false
    }

    return true
  },
  shouldGoToContactForm: (_, event) => {
    const { payload } = event as ResolutionOptionsEvents

    const resolutionOptionActions = payload.resolutionOption.actions || []

    return isEmpty(resolutionOptionActions)
  },
  shouldGoToReturnFunnel: (_, event) => {
    const { payload } = event as ResolutionOptionsEvents

    const resolutionOptionActions = payload.resolutionOption.actions || []

    return Boolean(
      intersection(resolutionOptionActions, ReturnFunnelResolutionActions)
        .length,
    )
  },
  some: (context, event, meta) => {
    const { guards } = meta.cond as MultipleCondType

    return guards.some((guardKey) => {
      return meta.state.machine?.options?.guards?.[guardKey]?.(
        context,
        event,
        meta,
      )
    })
  },
}
